import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass/styled-components';

import BackgroundImage from 'gatsby-background-image';
import Layout from '../components/layout';
import SEO from '../components/seo';

const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledText = styled(Text)`
  position: relative;
`;

const IndexPage = ({ data }) => {
  const { domains } = data.wordpressAcfOptions.options;

  return (
    <Layout>
      <SEO title="Home" />

      <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <h1>Burysax</h1>

        {domains && (
          <Flex width="100%" height="60vh">
            {domains.map((_domain) => {
              const { domain, page_title: pageTitle, featured_media: featuredMedia } = _domain;
              return (
                <Box key={domain} flexBasis="20%" flexGrow="1" height="100%">
                  <StyledLink href={domain}>
                    <StyledBackgroundImage
                      Tag="div"
                      fluid={featuredMedia.localFile ? featuredMedia.localFile.childImageSharp.fluid : null}
                      alt={pageTitle}
                      style={{ width: '100%', height: '100%' }}
                    />
                    <StyledText>
                      {pageTitle}
                    </StyledText>
                  </StyledLink>
                </Box>
              );
            })}
          </Flex>
        )}
      </Flex>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageQuery {
    wordpressAcfOptions {
      options {
        domains {
          page_title
          domain
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 640, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
